<template>
  <div id="epidemic">
    <div class="content-header" style="width: 100%">
      <div class="leftInfo">
        <span class="headerName">{{ activePageInfo.headerName }}</span>
      </div>
    </div>
    <div class="formmanage-content">
      <div class="left-nav-menu">
        <el-menu
          :default-openeds="['/main/form']"
          router
          :default-active="currentPath"
          class="el-menu-vertical-demo"
        >
          <el-menu-item
            index="/main/webhook"
            style="padding-left: 20px"
            v-if="RP_Visible('OPEN_WEBHOOK')"
            :class="{ activeClass: this.$route.path === '/main/webhook' }"
          >
            <i class="el-icon-connection"></i>
            <span slot="title">Webhook</span>
          </el-menu-item>
          <el-submenu index="/main/form">
            <template slot="title">
              <i class="el-icon-set-up"></i>
              <span>自定义表单</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                index="/main/fieldManage"
                :class="{
                  activeClass: this.$route.path === '/main/fieldManage',
                }"
                >字段管理</el-menu-item
              >
              <el-menu-item
                index="/main/formManage"
                :class="{
                  activeClass: this.$route.path === '/main/formManage',
                }"
                >表单管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item
            index="/main/epidemic"
            v-if="isShow"
            :class="{
              activeClass: this.$route.path === '/main/epidemic',
              epidemic_view: true,
            }"
          >
            <template slot="title">
              <i
                class="arsenal_icon"
                style="font-size: 16px; margin-right: 10px"
                >&#xed6d;</i
              >
              <span>疫区管理</span>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="right-content">
        <div class="list-content">
          <div class="list-item">
            <section class="handle_view">
              <el-tooltip
                class="item"
                effect="dark"
                content="批量删除"
                placement="top-start"
              >
                <i
                  class="el-icon-delete"
                  style="margin-right: 10px; font-size: 18px"
                  @click="remove"
                ></i>
              </el-tooltip>

              <el-upload
                class="upload-demo"
                action=""
                :multiple="false"
                :show-file-list="false"
                :http-request="exportElement"
                :before-upload="beforeUpload"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="批量导入"
                  placement="top-start"
                >
                  <i class="iconfont">&#xe6ee;</i>
                </el-tooltip>
              </el-upload>
            </section>
            <div class="serch_view">
              <el-input
                v-model="serchItem"
                @input="getTableInfo"
                placeholder="搜索省、市、区"
              ></el-input>
              <el-button @click="add" class="add_btn" type="primary"
                >新增疫区</el-button
              >
            </div>
          </div>
          <el-table
            :data="tableData"
            class="table"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @select-all="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="省份">
              <template scope="scope">
                {{ scope.row.province ? scope.row.province.name : "--" }}
              </template>
            </el-table-column>
            <el-table-column prop="cityName" label="城市">
              <template scope="scope">
                {{ scope.row.city ? scope.row.city.name : "--" }}
              </template>
            </el-table-column>
            <el-table-column prop="districtName" label="区县">
              <template scope="scope">
                {{ scope.row.district ? scope.row.district.name : "--" }}
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination_view">
            <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="formPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="formCurrentSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableDataTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="30%"
      class="dialog"
      center
    >
      <p>新增疫区</p>
      <el-form
        label-position="top"
        label-width="80px"
        :model="form"
        :rules="formRule"
        ref="form"
        v-if="centerDialogVisible"
        style="padding: 0px 10px"
      >
        <el-form-item label="疫区" prop="city">
          <el-cascader
            style="width: 100%"
            placeholder="请输入"
            ref="cascaderCity"
            v-model="lableCity"
            :options="CityOptions"
            collapse-tags
            clearable
            :props="{
              label: 'name',
              value: 'code',
              multiple: true,
              checkStrictly: true,
            }"
            @change="changeCity"
            filterable
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submite">确 定</el-button>
      </span>
    </el-dialog>
    <popup @closeEvent="exportError = false" v-if="exportError">
      <span slot="popup-name">导入错误</span>
      <div slot="popup-tip">导入数据错误，错误的行已自行过滤，错误行如下</div>
      <div slot="popup-con-c" class="popup-con-c">
        <div class="popup-content">
          <p
            v-for="(item, index) in exportErrorList"
            style="text-algin: center; margin-top: 10px"
            :key="index"
          >
            第【{{ item.rowIndex }}】,填写格式不正确
          </p>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button round @click="exportError = false">我知道了</el-button>
      </div>
    </popup>
  </div>
</template>
<script>
import { upload } from "../../utils/AliyunlssUtils";
import Popup from "../../components/popup.vue";
export default {
  data() {
    var cityRule = (rule, value, callback) => {
      if (this.lableCity.length != 0) {
        callback();
      } else {
        callback(new Error("请输入地区！"));
      }
    };
    return {
      exportError: false,
      currentPath: "",
      currentPage4: 20,
      activePageInfo: {
        headerName: "接口对接",
      },
      formPage: 1,
      formCurrentSize: 20,
      tableData: [],
      lableCity: [],
      form: {
        city: {
          id: "",
          name: "",
        },
        cityName: "",
        district: {
          id: "",
          name: "",
        },
        districtName: "",
        province: {
          id: "",
          name: "",
        },
        provinceName: "",
      },
      formRule: {
        city: [{ required: true, validator: cityRule, tigger: "blur" }],
      },
      CityOptions: [],
      centerDialogVisible: false,
      serchItem: "",
      selectList: [],
      tableDataTotal: 0,
      exportErrorList: [],
    };
  },
  components: {
    Popup,
  },
  computed: {
    isShow() {
      let mainId = localStorage.getItem('_mainId')
      return mainId == '90df4764122240de939331d372546c28' || mainId == '1419b09cb2ce11ea8f0900163e0964d5'
    }
  },
  methods: {
    getCurrentUrl() {
      this.currentPath = this.$route.path;
      console.log("path:", this.currentPath);
    },
    getTableInfo() {
      let page = this.formPage - 1 <= 0 ? 0 : this.formPage - 1;
      this.FetchGet(
        "/api/epidemic-area?item=" +
          this.serchItem +
          "&pageSize=" +
          this.formCurrentSize +
          "&pageNumber=" +
          page +
          "&direction=DESC" +
          "&sortByProperty=createTime"
      ).then((res) => {
        if (res.code === "0") {
          this.tableData = res.data.content;
          this.tableDataTotal = res.data.totalElements;
        }
      });
    },
    add() {
      this.centerDialogVisible = true;
      this.lableCity = [];
    },
    remove() {
      if (this.selectList.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择地区！",
        });
        return;
      }
      let url = "/api/epidemic-area";
      if (this.selectList.length == 1) {
        // 单个删除
        url = url + "/" + this.selectList[0].id;
      } else {
        // 批量删除
        let str = "";
        this.selectList.forEach((item, index) => {
          if (index == 0) {
            str = str + "?id=" + item.id;
          } else {
            str = str + "&id=" + item.id;
          }
        });
        url = url + str;
      }
      this.$confirm("此操作将删除该地址, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.FetchDelete2(url).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTableInfo();
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        });
      });
    },
    beforeUpload(file) {
      console.log(file);
      let flag = /\.(xlsx|xls|sheet)$/.test(file.type);
      if (!flag) {
        this.$message({
          type: "warning",
          message: "文件格式错误",
        });
      }
      return flag;
    },
    exportElement({ file }) {
      upload(
        {
          region: "oss-cn-zhangjiakou",
          accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
          accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
          // stsToken: '<Your securityToken(STS)>',
          bucket: "guoranopen-zjk",
        },
        file
      ).then((res) => {
        if (res.url) {
          this.FetchGet("/api/epidemic-area/import?ossFile=" + res.url).then(
            (res) => {
              if (res.code == "0") {
                if (res.data.length != 0) {
                  this.exportError = true;
                  this.exportErrorList = res.data;
                }
                this.$message({
                  type: "success",
                  message: "导入成功！",
                });
                this.getTableInfo();
              } else {
                this.$message({
                  type: "error",
                  message: res.message,
                });
              }
            }
          );
        }
      });
    },
    handleSelectionChange(select) {
      this.selectList = select;
    },
    getCityList() {
      this.FetchGet("/api/city").then((res) => {
        if (res.code == "0") {
          this.CityOptions = res.data;
        }
      });
    },
    changeCity() {},
    setParmas() {
      let params = [];
      let list = this.$refs.cascaderCity.getCheckedNodes();
      list &&
        list.forEach((item) => {
          params.push(this.tier(item, item.data.deep));
        });
      return params;
    },
    tier(obj, deep) {
      let params;
      switch (deep) {
        case 1:
          params = {
            province: {
              id: obj.data.code,
              name: obj.data.name,
              inDanger: true,
            },
            city: null,
            district: null,
          };
          break;
        case 2:
          params = {
            province: {
              id: obj.parent.data.code,
              name: obj.parent.data.name,
              inDanger: false,
            },
            district: null,
            city: {
              id: obj.data.code,
              name: obj.data.name,
              inDanger: true,
            },
          };
          break;
        case 3:
          params = {
            city: {
              id: obj.parent.data.code,
              name: obj.parent.data.name,
              inDanger: true,
            },
            district: {
              id: obj.data.code,
              name: obj.data.name,
              inDanger: false,
            },
            province: {
              id: obj.parent.parent.data.code,
              name: obj.parent.parent.data.name,
              inDanger: false,
            },
          };
          break;
        default:
          break;
      }
      return params;
    },
    submite() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = this.setParmas();
          this.FetchPost("/api/epidemic-area/", params).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "新增成功！",
              });
              this.centerDialogVisible = false;
              this.getTableInfo();
            }
          });
        }
      });
    },
    handleSizeChange(size) {
      this.formCurrentSize = size;
      this.getTableInfo();
    },
    handleCurrentChange(num) {
      this.formPage = num;
      this.getTableInfo();
    },
  },
  mounted() {
    this.getCurrentUrl();
    this.getCityList();
    this.getTableInfo();
  },
};
</script>
<style lang="less">
@import "./../../assets/less/Epidemic/epidemic.less";
.el-form-item__content {
  text-align: left;
}
.el-menu-item-group__title {
  padding: 0 !important;
}
.el-submenu .el-menu-item {
  height: 48px;
  line-height: 48px;
  width: 150px;
  padding: 0 45px;
  min-width: 150px;
}
.activeClass {
  border-right: 2px solid #366AFF;
}
// .el-submenu__title {
//   padding: 0 10px !important;
// }
.el-pagination {
  margin: 0;
}
.popup-content {
  width: 100%;
  min-height: 80px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
</style>